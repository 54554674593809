<template>
  <div class="p-4">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-6 text-left">
            <h4>Customers</h4>
          </div>
          <div class="col-6 text-right">
            <appModalUserNew
              userType="partner_customer"
              :title="'New customer'"
            ></appModalUserNew>
          </div>
        </div>
      </div>

      <div class="card-body">
        <!-- Desktop view -->
        <div class="desktop-view">
          <div class="search-row">
            <div class="select-search" v-if="loginUserObj">
              <div class="search-box">
                <div class="input-group form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    aria-describedby="basic-addon2"
                    v-model.lazy="search"
                  />

                  <div class="input-group-append">
                    <span
                      class="input-group-text"
                      id="basic-addon2"
                      @click="fnSearch()"
                      >Search....</span
                    >
                  </div>
                  <label for="paginate" class="floatingLabel">Search ...</label>
                </div>
              </div>
            </div>

            <div class="export-box">
              <div class="date-box">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="date"
                    id="dateOfBirth"
                    autocomplete="off_dateOfBirth"
                    placeholder=" "
                    v-model="fromDate"
                  />
                  <label class="floatingLabel" for="dateOfBirth"
                    >From date</label
                  >
                </div>
              </div>
              <div class="date-box">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="date"
                    id="dateOfBirth"
                    autocomplete="off_dateOfBirth"
                    placeholder=" "
                    v-model="toDate"
                  />
                  <label class="floatingLabel" for="dateOfBirth">To date</label>
                </div>
              </div>
              <div>
                <button class="btn btn-sm btn-info" @click="fnSearch()">
                  Filter
                </button>
              </div>
              <div>
                <button
                  class="btn btn-sm btn-info"
                  @click="fnExport()"
                  :disabled="isExport"
                >
                  Export
                </button>
              </div>
              <div>
                <button class="btn btn-sm btn-info" @click="fnClear()">
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Mobile view -->
        <div class="mobile-view">
          <div class="search-row">
            <div class="select-search" v-if="loginUserObj">
              <div class="row">
                <div class="col-12">
                  <div class="input-group form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      aria-describedby="basic-addon2"
                      v-model.lazy="search"
                    />

                    <div class="input-group-append">
                      <span
                        class="input-group-text"
                        id="basic-addon2"
                        @click="fnSearch()"
                        >Search....</span
                      >
                    </div>
                    <label for="paginate" class="floatingLabel"
                      >Search ...</label
                    >
                  </div>
                </div>

                <div class="col-12">
                  <div class="filter-box">
                    <div class="date-box">
                      <div class="form-group">
                        <input
                          class="form-control"
                          type="date"
                          id="dateOfBirth"
                          autocomplete="off_dateOfBirth"
                          placeholder=" "
                          v-model="fromDate"
                        />
                        <label class="floatingLabel" for="dateOfBirth"
                          >From date</label
                        >
                      </div>
                    </div>
                    <div class="date-box">
                      <div class="form-group">
                        <input
                          class="form-control"
                          type="date"
                          id="dateOfBirth"
                          autocomplete="off_dateOfBirth"
                          placeholder=" "
                          v-model="toDate"
                        />
                        <label class="floatingLabel" for="dateOfBirth"
                          >To date</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="filter-box-right">
                    <div>
                      <button class="btn btn-sm btn-info" @click="fnSearch()">
                        Filter
                      </button>
                    </div>
                    <div>
                      <button
                        class="btn btn-sm btn-info"
                        @click="fnExport()"
                        :disabled="isExport"
                      >
                        Export
                      </button>
                    </div>
                    <div>
                      <button class="btn btn-sm btn-info" @click="fnClear()">
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="table-responsive table-sm policeCheckList">
          <!-- <table class="table table-bordered table-striped table-hover"> -->
          <table
            class="
              v-datatable-light
              table table-bordered table-hover table-striped table-center
            "
          >
            <thead>
              <tr>
                <th class="header-item header-column-1">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('first_name')"
                    >First name</a
                  >
                  <span
                    v-if="sortDirection == 'desc' && sortField == 'first_name'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span
                    v-if="sortDirection == 'asc' && sortField == 'first_name'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>
                <th class="header-item header-column-2">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('last_name')"
                    >Last name</a
                  >
                  <span
                    v-if="sortDirection == 'desc' && sortField == 'last_name'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span
                    v-if="sortDirection == 'asc' && sortField == 'last_name'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>
                <th class="header-item header-column-3">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('email')"
                    >Email</a
                  >
                  <span v-if="sortDirection == 'desc' && sortField == 'email'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span v-if="sortDirection == 'asc' && sortField == 'email'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>
                <th class="header-item header-column-3">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('mobile_no')"
                    >Phone no</a
                  >
                  <span
                    v-if="sortDirection == 'desc' && sortField == 'mobile_no'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span
                    v-if="sortDirection == 'asc' && sortField == 'mobile_no'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>
                <th class="header-item header-column-3">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('created_at')"
                    >Date of create</a
                  >
                  <span
                    v-if="sortDirection == 'desc' && sortField == 'created_at'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span
                    v-if="sortDirection == 'asc' && sortField == 'created_at'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>
                <th class="header-item header-column-3">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('updated_at')"
                    >Date of update</a
                  >
                  <span
                    v-if="sortDirection == 'desc' && sortField == 'updated_at'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span
                    v-if="sortDirection == 'asc' && sortField == 'updated_at'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>
                <th class="header-item header-column-5 text-center">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('status')"
                    >Status</a
                  >
                  <span v-if="sortDirection == 'desc' && sortField == 'status'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span v-if="sortDirection == 'asc' && sortField == 'status'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>
                <th class="header-item header-column-3">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('is_verified')"
                    >Activated</a
                  >
                  <span
                    v-if="sortDirection == 'desc' && sortField == 'is_verified'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span
                    v-if="sortDirection == 'asc' && sortField == 'is_verified'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>
                <th class="header-item header-column-7 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in getUserCorporateListPaginate.data"
                :key="item.id"
              >
                <td class="column-1">{{ item.first_name }}</td>
                <td class="column-2">{{ item.last_name }}</td>
                <td class="column-3">{{ item.email }}</td>
                <td class="column-4">{{ item.mobile_no }}</td>
                <td class="column-5">
                  {{ item.created_at | dateConvertYYYYMMDD }}
                </td>
                <td class="column-5">
                  {{ item.updated_at | dateConvertYYYYMMDD }}
                </td>
                <td class="column-7 text-center">
                  <span v-if="item.status == 1" class="badge badge-success"
                    >Active</span
                  >
                  <span v-if="item.status == 0" class="badge badge-danger"
                    >In-active</span
                  >
                </td>
                <td class="column-9">
                  <span v-if="item.is_verified == 1" class="badge badge-success"
                    >Verified</span
                  >
                  <span v-if="item.is_verified == 0" class="badge badge-danger"
                    >Not-verified</span
                  >
                </td>
                <td class="column-8">
                  <div class="d-flex align-items-center justify-content-center">
                    <!-- Edit User -->
                    <appModalUserEdit :propsData="item"></appModalUserEdit>
                    <!-- Create new police check -->
                    <a
                      @click="fnGoPoliceCheckNew(item)"
                      class="d-inline pr-1"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Create new police check"
                    >
                      <i class="fas fa-flag-checkered text-info fs-1000"></i>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="9">
                  <div class="sits-pagination-row">
                    <div class="items-per-page">
                      <div class="pr-2">
                        <p class="mb-0">Items per page</p>
                      </div>
                      <div class="pr-5">
                        <select
                          v-model="paginate"
                          class="form-control sits_select"
                        >
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>

                    <div>
                      <pagination
                        :limit="5"
                        :data="getUserCorporateListPaginate"
                        @pagination-change-page="fnUserListPaginate"
                      ></pagination>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../../store/modules/base.module";
import pagination from "laravel-vue-pagination";
import * as messageService from "../../services/message.service";
import * as authService from "../../services/auth.service";
import { mapGetters, mapActions } from "vuex";
import appModalUserNew from "../../components/admin/user/ModalUserNew.vue";
import appModalUserEdit from "../../components/admin/user/ModalUserEdit.vue";

export default {
  name: "CustomerList",
  components: {
    pagination,
    appModalUserNew,
    appModalUserEdit,
  },
  data() {
    return {
      policeCheckIdView: "",
      policeCheckIdEdit: "",
      customerIdByAdmin: "",
      loginUserObj: {},

      paginate: 15,
      currentPage: 1,
      roleIds: [],
      parentId: "",
      search: "",
      selectedStatus: "",
      fromDate: "",
      toDate: "",
      sortDirection: "desc",
      sortField: "id",

      isExport: true,
    };
  },
  computed: {
    ...mapGetters(["getUserListPaginate", "getUserCorporateListPaginate"]),
  },
  methods: {
    ...mapActions(["fetchUserListPaginate", "fetchUserCorporateListPaginate"]),

    fnChangeSort(field) {
      if (this.sortField == field) {
        this.sortDirection = this.sortDirection == "asc" ? "desc" : "asc";
      } else {
        this.sortField = field;
      }
      this.fnUserListPaginate();
    },

    fnUserListPaginate(currentPage) {
      let obj = {
        currentPage: currentPage,
        paginate: this.paginate,
        roleIds: this.roleIds,
        parentId: this.parentId,
        search: this.search,
        selectedStatus: this.selectedStatus,
        fromDate: this.fromDate,
        toDate: this.toDate,
        sortDirection: this.sortDirection,
        sortField: this.sortField,
      };

      // this.fetchUserListPaginate(obj);
      this.fetchUserCorporateListPaginate(obj);
    },

    // Export User list Summary into excel
    getExportUserListSummary: function () {
      let roleIds = "";
      let parentId = "";
      let search = "";
      let selectedStatus = "";
      let fromDate = "";
      let toDate = "";

      if (this.roleIds == "") {
        roleIds = null;
      } else {
        roleIds = this.roleIds;
      }
      if (this.parentId == "") {
        parentId = null;
      } else {
        parentId = this.parentId;
      }
      if (this.search == "") {
        search = null;
      } else {
        search = this.search;
      }
      if (this.selectedStatus == "") {
        selectedStatus = null;
      } else {
        selectedStatus = this.selectedStatus;
      }
      if (this.fromDate == "") {
        fromDate = null;
      } else {
        fromDate = this.fromDate;
      }
      if (this.toDate == "") {
        toDate = null;
      } else {
        toDate = this.toDate;
      }

      window.open(
        `${store.state.apiURL}/users-admin/list-export/${roleIds}/${parentId}/${search}/${selectedStatus}/${fromDate}/${toDate}`
      );

      messageService.fnSweetAlertSuccessToast(
        "User Summary",
        "Downloaded successfully"
      );
    },

    fnSearch() {
      this.isExport = true;
      this.fnUserListPaginate();
      this.isExport = false;
    },

    // EXport User list summary
    fnExport() {
      this.getExportUserListSummary();
      this.isExport = true;
    },
    // To Clear Search keys
    fnClear() {
      this.currentPage = 1;
      this.search = "";
      this.selectedStatus = "";
      this.fromDate = "";
      this.toDate = "";
      this.sortDirection = "desc";
      this.sortField = "id";
      this.isExport = true;
      this.fnUserListPaginate();
    },

    fnGoPoliceCheckNew(props) {
      this.$router.push({
        name: "PoliceCheckNewPartner",
        params: { user_id: props.id },
      });
    },
  },
  created() {
    this.loginUserObj = authService.getUserFromToken();
    this.parentId = this.loginUserObj.parent_id;

    // Partner customer
    if (this.loginUserObj.role_id == 6) {
      this.roleIds = [8];
    } else if (this.loginUserObj.role_id == 7) {
      this.roleIds = [8];
    } else {
      this.roleIds = [8];
    }
  },
  watch: {
    paginate: function (value) {
      this.fnUserListPaginate();
    },
    search: function (value) {
      this.fnUserListPaginate();
    },
    selectedStatus: function (value) {
      this.fnUserListPaginate();
    },
  },

  mounted() {
    this.fnUserListPaginate();
  },
};
</script>

<style lang="scss" scoped>
.sits-btn-round.btn.btn-info {
  border-radius: 50% !important;
  padding-top: 0.2rem !important;
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
}
</style>